<template>
    <div class="orgcontainer">
        <LastTrades />
        <Market />
    </div>
</template>
<script>
import LastTrades from '../HomePage/LastTrades.vue';
import Market from '../HomePage/Market.vue';
export default {
    name:'MatketPublic',
    components:{
        LastTrades,
        Market
    }
}
</script>

<style lang="scss" scoped>
.orgcontainer{
    padding-top:100px !important ;
}
</style>